import { useUtilityStore } from "~/store/UtililityStore";
import uk from '@/assets/language/uk.svg'
import fr from '@/assets/language/fr.svg'
import nl from '@/assets/language/nl.svg'
import sv from '@/assets/language/sv.svg'
import de from '@/assets/language/de.svg'
import es from '@/assets/language/es.svg'
import it from '@/assets/language/it.svg'
import pt from '@/assets/language/pt.svg'
import pl from '@/assets/language/pl.svg'


export const useUtils = () => {
  const utilityStore = useUtilityStore();
  const selectedLocale = computed(() => utilityStore.selectedLocale);

  const showDecimalValueInLocale = computed(() => {
    return (v) => {
      if (v) {
        try {
          return Number(v).toFixed(2).toLocaleString(selectedLocale.value);
        } catch (error) {
          return v;
        }
      }
      return "";
    };
  });


  return { showDecimalValueInLocale };
};


export const languageMap = {
  en: uk,
  fr: fr,
  nl: nl,
  sv: sv,
  de: de,
  es: es,
  it: it,
  pt: pt,
  pl: pl,
};

export let gsapInstance = null;


export const loadGsap = async () => {
  if (!gsapInstance) {
    gsapInstance = await import('gsap');
  }
  return gsapInstance;
};
